import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import Container from "../../atoms/Container";
import { H2 as Heading } from "../../atoms/Typography";

const Wrapper = styled.section`
  background: #fff;

  a {
    color: inherit;
  }

  .container {
    padding: 6rem;
    display: flex;
    justify-content: space-between;
    gap: 4rem;
  }
  .logo-wrapper {
    display: flex;
    align-items: center;
    gap: 12px;
    color: #1671b2;
    text-transform: uppercase;
    font-size: 1.4rem;
    font-weight: 700;
    letter-spacing: 4px;
  }

  .information {
    display: flex;
    flex-direction: column;
    gap: 24px;
    .heading {
      max-width: 80rem;
      font-size: 4rem;
      line-height: 1.2;
    }

    .description {
      max-width: 68rem;
      font-size: 14px;
      line-height: 1.5;
    }
  }

  .main-img {
    border-radius: 50%;
    width: 350px;
    height: 350px;
  }

  @media screen and (max-width: 1130px) {
    .container {
      flex-direction: column;
      gap: 24px;
    }
  }

  @media screen and (max-width: 550px) {
    .container {
      padding: 4rem 1rem;
    }
    .information .heading {
      font-size: 32px;
      line-height: 1.2;
    }
    .main-img {
      width: auto;
      height: auto;
    }
  }
`;
const About = ({
  image,
  title,
  companyName,
}: {
  image: any;
  title: any;
  companyName: string;
}) => {
  return (
    <Wrapper className="section-about">
      <Container className="container">
        <div className="information">
          <div className="logo-wrapper">{title}</div>
          <Heading className="heading">
            We bridge the gap between today&apos;s unexpected expenses and
            tomorrow&apos;s opportunities.
          </Heading>
          <p className="description">
            {companyName} helps the 40+ million Americans who do not have access
            to short-term credit. These under-served customers deserve an option
            to manage their unforeseen expenses and help get through any
            difficult expenses. Sign your loan agreement and you may get your
            money in minutes via your debit card or same/next business day
            deposit via ACH. We are owned by and operated by the Otoe-Missouria
            Tribe. For more information about the Otoe-Missouria Tribe, please{" "}
            <Link to="/about">click here.</Link>
          </p>
        </div>
        <img className="main-img" src={image} alt="happy-family" />
      </Container>
    </Wrapper>
  );
};

export default About;
